<template>
    <div>
        <div class="agreement-modal__dialog">
            <section
                id="scrollArea"
                class="agreement-modal__content"
                @scroll="scrollDetect"
            >
                <span class="agreement-content__text">
                    Thanks for joining our Affiliate program.
                </span>

                <span class="agreement-content__text">
                    These following terms and conditions apply to individuals who are accessing or using the Program both as a merchant (“we” or “merchant”) and an affiliate (“you” or “affiliate”) who refers customers to use product or service from the merchant.
                </span>

                <span class="agreement-content__text">
                    By participating in the Program, Affiliate agrees to use the Program in the manner specified in, and are bound by, these Terms and Conditions. If you do not agree to these Terms and Conditions in their entirety you are not authorized to register as an Affiliate or participate in the Program in any manner.
                </span>

                <h2 class="agreement-content__title">
                    1. Approve or Reject of the Registration
                </h2>
                <span class="agreement-content__text">
                    We reserve the right to approve or reject ANY Affiliate Program Registration in our sole and absolute discretion. You will have no legal recourse against us for the rejection of your Affiliate Program Registration.
                </span>

                <h2 class="agreement-content__title">
                    2. Affiliate Links
                </h2>
                <span class="agreement-content__text">
                    Affiliate link is automatically generated. If a person clicks on someone else’s referral link and then later they click on yours, yours is the one that counts.
                </span>
                <span class="agreement-content__text">
                    Affiliate may also advertise merchant website on online channels such as Facebook, Instagram,... or offline classified channel ads, magazines, and newspapers.
                </span>

                <h2 class="agreement-content__title">
                    3. Commissions and payment
                </h2>
                <span class="agreement-content__text">
                    When you refer any customer to make a purchase on our website, you will get a commission amount which is calculated based on our automated commission structure. Commission amount is the flat rate on the order value.
                </span>
                <span class="agreement-content__text">
                    The accumulated earnings (commissions) can be cashed out. The Affiliate must provide a valid payment method by setting it in the dashboard.
                </span>
                <span class="agreement-content__text">
                    Payments will only be sent for transactions that have been successfully completed. Use of the Affiliate Program is subject to a fair use policy which gives merchant the right to review each and every referral order. Transactions that result in charge backs or refunds will not be paid out.
                </span>

                <h2 class="agreement-content__title">
                    4. Marketing tool
                </h2>
                <span class="agreement-content__text">
                    An individual QR code may be generated for the Affiliate. When scanned with a phone, the code will translated to the affiliate link and a cookie will be created on the device and shall be valid for 30 days.
                </span>
                <span class="agreement-content__text">
                    We may share promotion media such as banner, logo or specific collection promotion to you through Marketing tool tab. You can download the media or get the HTML embed code in order to share on affiliate channels.
                </span>

                <h2 class="agreement-content__title">
                    5. Network
                </h2>
                <span class="agreement-content__text">
                    If Network tab is activated, affiliate can invite others to become their downline affiliate. If any downline affiliate brings order to merchant shop, upline affiliate will also get network commission which depends on merchant settings.
                </span>
                <span class="agreement-content__text">
                    To invite other affiliates into the system, share network link and any other affiliate who click on that link and sign up will become a downline affiliate.
                </span>

                <h2 class="agreement-content__title">
                    6. Cookie
                </h2>
                <span class="agreement-content__text">
                    We use a cookie to track people who have clicked on your link, so they need to be using cookies for us to track them.
                </span>
                <span class="agreement-content__text">
                    If a person doesn't allow cookies or clears their cookies then we can't track them so can't pay earnings on that person's activity.
                </span>
                <span class="agreement-content__text">
                    Cookies day is said on the Affiliate registration form. The tracking day will start from the time a customer clicks on the affiliate’s link or use the coupon. Within the cookie time, every order made by this customer at merchant website will automatically result in commissions to the affiliate (There’s no need for the customer to click on the affiliate link then).
                </span>

                <h2 class="agreement-content__title">
                    7. Removal from Referral Program
                </h2>
                <span class="agreement-content__text">
                    If an affiliate wishes to be removed from the affiliate program, they can do so by contacting: ceo@mediatechlimited.com
                </span>

                <span class="agreement-content__text">
                    We're very glad you've made it to the end of this important document.
                </span>

                <span class="agreement-content__text">
                    We wish you all the very best. You can always re-visit these Affiliate Program terms and conditions in the future and if you have any questions, you can contact our support team on our website.
                </span>

                <span class="agreement-content__text">
                    {{ domain }} is owned and operated by MEDIATECH LIMITED. Company registration number 1242512.
                    Any relevant information should be
                    mailed to Office 813, 8/F, 610 Nathan Road, Hollywood Plaza, Hong Kong
                    or emailed at ceo@mediatechlimited.com
                </span>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block confirm-modal__accept">
                    <form-checkbox
                        v-if="scrollFinished===true"
                        v-model="agrement"
                        class="order-form-step__checkbox"
                        :fixed="true"
                        :label="'I ACCEPT'"
                        @input="submit()"
                    />
                    <span
                        v-show="hidden"
                        v-else
                        class="confirm-modal__scroll"
                        @click="scrollDown"
                    >
                        Scroll down to read
                        <arrow-double />
                    </span>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>

import ArrowDouble from '@/components/icons/ArrowDouble.vue';

export default {
    name: 'AgreementModal',
    components: {
        ArrowDouble
    },
    data() {
        return {
            scrollFinished: false,
            agrement: false,
            hidden: true
        }
    },
    computed: {
        domain() {
            return process.env.VUE_APP_DOMAIN
        }
    },
    methods: {
        submit() {
            this.$emit('terms-agree', this.agrement)
        },
        scrollDetect() {
            const o = document.getElementById('scrollArea')
            if (o.offsetHeight + o.scrollTop >= o.scrollHeight - 10) {
                this.scrollFinished = true
                this.hidden = false
            }
        },
        scrollDown() {
            const elem = document.getElementById('scrollArea')
            elem.scrollTo({
                top: 5000,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss" >
.agreement-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;

    .confirm-modal {
        &__accept {
            padding: 20px;
        }
        &__scroll {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $font-color-main;
            cursor: pointer;
            svg {
                margin: 5px;
                transform: rotate(90deg);
                path{
                    fill:$font-color-main;
                }
            }
        }
    }

    &__dialog {
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        //position: fixed;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 16px;
        border-bottom: 1px solid $border-light;
        padding: 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $second-color;
        cursor: pointer;
    }
    &__content {
        padding: 20px 20px 0;
        color: #757c89;
        font-size: 16px;
        line-height: 1.3;
        overflow-y: scroll;
        max-height: 500px;
    }
    &__two-button-block {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    &__one-button-block {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
    &__two-button {
        padding: 20px 0;
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--gray {
            background-color: #f5f5f5;
            &:hover {
                background-color: rgba(145, 149, 154, 0.2);
                transition: 0.5s;
            }
        }
        &--main {
            background: $btn-main-background;
            box-shadow: $confirm-modal__button-shadow;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                color: $btn-main-color-hover;
                transition: 0.5s;
            }
        }
    }
    &__one-button {
        padding: 20px 0;
        width: 80%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--main {
            background: $btn-main-background;
            box-shadow: $confirm-modal__button-shadow;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                transition: 0.5s;
                color: $btn-main-color-hover;
            }
        }

    }
}

.agreement-content {
    &__text {
        margin: 10px 0;
        line-height: 30px;
    }
    &__title {
        margin: 15px 0 10px;
    }
}

@media (max-width: 576px) {
    .agreement-modal {
        &__content {
            max-height: 320px;
            padding: 20px 20px 0;
        }

        &__header {
            margin-bottom: 0;
        }
        &__dialog {
            left: 0;
        }

        .confirm-modal__two-button-block {
            padding: 20px;
        }
    }
}

</style>
